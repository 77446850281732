













































































import SKPosition from '@/components/SK-Position.vue';
import axios from '@/utils/axios';
import { muscles } from '@/utils/constants';
import { IPosition } from '@/utils/interfaces';
import { UpdatePositionDTO } from '@/utils/models';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKPosition
  }
})
export default class EditPosition extends Vue {
  public newPosition = {} as IPosition;
  public buttonDisabled = false;

  mounted() {
    this.reset();
  }

  get muscles(): string[] {
    return muscles;
  }

  get position(): IPosition {
    return this.$store.getters.position(this.$route.params.id);
  }

  public reset(): void {
    this.newPosition = {
      _id: '',
      thumbnail: '',
      primaryMusclesMan: [],
      primaryMusclesWoman: [],
      secondaryMusclesMan: [],
      secondaryMusclesWoman: [],
      level: 0,
      intimacy: 0
    } as IPosition;
    this.buttonDisabled = false;
  }

  public async update(): Promise<void> {
    this.buttonDisabled = true;
    const updateDTO = {} as UpdatePositionDTO;

    if (this.newPosition.thumbnail.length !== 0) {
      updateDTO.thumbnail = this.newPosition.thumbnail;
    }
    if (this.newPosition.primaryMusclesMan.length !== 0) {
      updateDTO.primaryMusclesMan = this.newPosition.primaryMusclesMan;
    }
    if (this.newPosition.primaryMusclesWoman.length !== 0) {
      updateDTO.primaryMusclesWoman = this.newPosition.primaryMusclesWoman;
    }
    if (this.newPosition.secondaryMusclesMan.length !== 0) {
      updateDTO.secondaryMusclesMan = this.newPosition.secondaryMusclesMan;
    }
    if (this.newPosition.secondaryMusclesWoman.length !== 0) {
      updateDTO.secondaryMusclesWoman = this.newPosition.secondaryMusclesWoman;
    }
    const { data } = await axios.put(
      `position/update/${this.position._id}`,
      updateDTO
    );

    this.$store.commit('addPosition', data);
    this.$router.back();
    this.reset();
  }
}
